import React, { Component } from "react";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect, Routes
} from "react-router-dom";
import Navbar from "./Navbar";
import About from "./About";
import Footer from "./Footer";
import Contact from "./Contact";
import Blog from "./blog";
import PlasticsInCosmetics from "./PlasticsInCosmetics"
import Home from "./Home";
import PrivateLabe from "./PrivateLabe";
import CustomFormulation from "./CustomFormulation";
import HotelandSpaAmenities from "./HotelandSpaAmenities";
import Career from "./Career";
import Packaging from "./Packaging"
import Provenience from "./Provenience"
import Makeup from "./Cate/Makeup"
import "./style.css";
import 'bootstrap/dist/css/bootstrap.min.css';
import Howitwork from "./Howitwork";
import BodyCare from "./Cate/BodyCare";
import HairCare from "./Cate/HairCare";
import MenCare from "./Cate/MenCare";
import ProcessManufacturing from "./Cate/ProcessManufacturing";
import Packaging_New from "./Cate/Packaging_New";
import Testing from "./Cate/Testing";
import PrivateLabel from "./Cate/PrivateLabel";
import CleanserSanitizer from "./Cate/CleanserSanitizer";
import Hair from "./Cate/Hair";
import MakeupNew from "./Cate/MakeupNew";
import Branding from "./Cate/Branding";
import Custom1 from "./Cate/Custom1";
import Custom2 from "./Cate/Custom2";
import Custom3 from "./Cate/Custom3";
import BakuchiolBlog from "./Blog/BakuchiolBlog";
import ChmicalBlog from "./Blog/ChmicalBlog";
import FaceOils from "./Blog/FaceOils";
import GreesyHairBlog from "./Blog/GreesyHairBlog";
import SkincareTrends from "./Blog/SkincareTrends";
import TreatBackAcneOrganically from "./Blog/TreatBackAcneOrganically";
import PimplesButtBlog from "./Blog/PimplesButtBlog";
import TreatSunburnOrganically from "./Blog/TreatSunburnOrganically";
import BlushTechniques from "./Blog/BlushTechniques";
import BodyAcneHappening from "./Blog/BodyAcneHappening";
import DarkUnderarmsTreatment from "./Blog/DarkUnderarmsTreatment";
import EucalyptusOil from "./Blog/EucalyptusOil";
import CustomFormulationNew from "./Blog/CustomFormulating";
import PrivateLabeNew from "./PrivateLabeNew";
import Manufacturing from "./Cate/Manufacturing";
import TheDeadliestIngredientSqualane from "./Blog/TheDeadliestIngredientSqualane";
import Tweaks from "./Blog/Tweaks";
import TipSkin from "./Blog/TipSkin";
import BanishWrinklesNaturally from "./Blog/BanishWrinklesNaturally";
import SkinExfoliation from "./Blog/SkinExfoliation";
import Sebum from "./Blog/Sebum";
import ProblemSilicones from "./Blog/ProblemSilicones";
import PeppermintOil from "./Blog/PeppermintOil";
import FungalAcneReal from "./Blog/FungalAcneReal";
import LanolinTruth from "./Blog/LanolinTruth";
import CORK from "./Blog/CORK";
import Home2 from "./Home2";
import Demo from "./Demo";
import BenefitsTeaTreeOil from "./Blog/BenefitsTeaTreeOil";
import CoconutOil from "./Blog/CoconutOil";
import Treatunevenskintone from "./Blog/Treatunevenskintone";
import FaceCare from "./Cate/FaceCare";
import FaceMask from "./Cate/FaceMask";
import FaceLotion from "./Cate/FaceLotion";
import FaceSurums from "./Cate/FaceSurums";
import FaceWash from "./Cate/FaceWash";
import BodyCareNew from "./Cate/BodyCareNew";
import FaceExfoliation from "./Cate/FaceExfoliation";
import FaceCream from "./Cate/FaceCream";
import MenCareNew from "./Cate/MenCareNew";
import BabyCare from "./Cate/BabyCare";
import Hotel from "./Cate/Hotel";
import AboutUsNew from "./AboutUsNew";
import HeaderBG from "./Cate/HeaderBG";

export default class App extends Component {
  render() {
    return (

      <div>
        <Navbar />
        <Routes >
          <Route exact path="/header" element={<HeaderBG />} /> 
          <Route exact path="/about-us-old" element={<About />} />
          <Route exact path="/contact" element={<Contact />} />
          <Route exact path="/blog" element={<Blog />} />
          <Route exact path="/" element={<Home2 />} />
          <Route exact path="/cosmetics-skincare-manufacturer-uk" element={<Home2 />} />
          <Route exact path="/hotel-and-spa-amenities-old" element={<HotelandSpaAmenities />} />
          <Route exact path="/private-label-new" element={<PrivateLabe />} />
          <Route exact path="/custom-formulation-new" element={<CustomFormulation />} />
          <Route exact path="/Career" element={<Career />} />
          <Route exact path="/the-brand" element={<Packaging />} />
          <Route exact path="/Provenance" element={<Provenience />} />
          <Route exact path="/how-it-work" element={<Howitwork />} />
          <Route exact path="/Makeup-old" element={<Makeup />} />
          <Route exact path="/Bodycare-old" element={<BodyCare />} />
          <Route exact path="/Haircare-old" element={<HairCare />} />
          <Route exact path="/Mencare-old" element={<MenCare />} />
          <Route exact path="/Mencare" element={<MenCareNew />} />
          <Route exact path="/ProcessManufacturing" element={<ProcessManufacturing />} />
          <Route exact path="/packaging" element={<Packaging_New />} />
          <Route exact path="/Testing" element={<Testing />} />
          <Route exact path="/PrivateLabel" element={<PrivateLabel />} />
          <Route exact path="/CleanserSanitizer" element={<CleanserSanitizer />} />
          <Route exact path="/Haircare" element={<Hair />} />
          <Route exact path="/Makeup" element={<MakeupNew />} />
          <Route exact path="/Branding" element={<Branding />} />
          <Route exact path="/Custom1" element={<Custom1 />} />
          <Route exact path="/Custom2" element={<Custom2 />} />
          <Route exact path="/Custom3" element={<Custom3 />} />
          <Route exact path="/blog/a-non-living-burden" element={<PlasticsInCosmetics />} />
          <Route exact path="/blog/Bakuchiol" element={<BakuchiolBlog />} />
          <Route exact path="/blog/chemical-ingredients-to-avoid-in-daily-use-products" element={<ChmicalBlog />} />
          <Route exact path="/blog/face-oils-all-you-need-to-know" element={<FaceOils />} />
          <Route exact path="/blog/grease-cleanse-guide-home-remedies" element={<GreesyHairBlog />} />
          <Route exact path="/blog/5-skincare-trends-of-2021" element={<SkincareTrends />} />
          <Route exact path="/blog/how-to-treat-back-acne-organically" element={<TreatBackAcneOrganically />} />
          <Route exact path="/blog/how-to-get-rid-of-pimples-on-your-butt-fast" element={<PimplesButtBlog />} />
          <Route exact path="/blog/treat-sunburn-organically" element={<TreatSunburnOrganically />} />
          <Route exact path="/blog/2021-blush-techniques" element={<BlushTechniques />} />
          <Route exact path="/blog/Why-body-acne-is-happening-more-than-ever" element={<BodyAcneHappening />} />
          <Route exact path="/blog/dark-underarms-treatment" element={<DarkUnderarmsTreatment />} />
          <Route exact path="/blog/eucalyptus-oil-benefits-for-hair" element={<EucalyptusOil />} />
          <Route exact path="/custom-formulation" element={<CustomFormulationNew />} />
          <Route exact path="/private-label" element={<PrivateLabeNew />} />
          <Route exact path="/Manufacturing" element={<Manufacturing />} />
          <Route exact path="/blog/The-deadliest-ingredient-squalane" element={<TheDeadliestIngredientSqualane />} />
          <Route exact path="/blog/these-small-tweaks-can-help-save-the-environment" element={<Tweaks />} />
          <Route exact path="/blog/Tips-to-protect-your-skin-this-summer" element={<TipSkin />} />
          <Route exact path="/blog/banish-wrinkles-naturally" element={<BanishWrinklesNaturally />} />
          <Route exact path="/blog/skin-exfoliation-what-how-and-why" element={<SkinExfoliation />} />
          <Route exact path="/blog/Sebum" element={<Sebum />} />
          <Route exact path="/blog/Problem-with-Silicones-Good-Bad-ugly" element={<ProblemSilicones />} />
          <Route exact path="/blog/peppermint-oil-for-skin-benefits" element={<PeppermintOil />} />
          <Route exact path="/blog/is-fungal-acne-real" element={<FungalAcneReal />} />
          <Route exact path="/blog/lanolin-truth" element={<LanolinTruth />} />
          <Route exact path="/blog/can-cork-save-the-beauty-industry-carbon-footprints" element={<CORK />} />
          <Route exact path="/HomeNew" element={<Home2 />} />
          <Route exact path="/demo" element={<Demo />} />
          <Route exact path="/blog/benefits-of-tea-tree-oil" element={<BenefitsTeaTreeOil />} />
          <Route exact path="/blog/coconut-oil-benefits-for-hair" element={<CoconutOil />} />
          <Route exact path="/blog/how-to-treat-uneven-skin-tone" element={<Treatunevenskintone />} />
          <Route exact path="/facecare" element={<FaceCare />} />
          <Route exact path="/facecare/mask" element={<FaceMask />} />
          <Route exact path="/facecare/lotion" element={<FaceLotion />} />
          <Route exact path="/facecare/serums" element={<FaceSurums />} />
          <Route exact path="/facecare/facewash" element={<FaceWash />} />
          <Route exact path="/Bodycare" element={<BodyCareNew />} />
          <Route exact path="/facecare/exfoliation" element={<FaceExfoliation />} />
          <Route exact path="/facecare/cream" element={<FaceCream />} />
          <Route exact path="/babycare" element={<BabyCare />} />
          <Route exact path="/hotel-and-spa-amenities" element={<Hotel />} />
          <Route exact path="/about-us" element={<AboutUsNew />} />
        </Routes>
        <Footer />

      </div>
    );
  }
}
import React, { Component } from "react";
import { Link } from "react-router-dom";
import MobileMenu from "./MobileMenu";
import Mail1 from '../src/img/mail.png';
import Mail2 from '../src/img/mail2.png';
import Mail3 from '../src/img/mail3.png';
import Mail4 from '../src/img/mail4.png';
import Mail5 from '../src/img/mail5.png';

class Navbar extends React.Component {
  test(event){
    document.getElementById('test2').classList.remove('show');
}
test2(event){
    document.getElementById('test2').classList.add('show');
}
test3(event){
  document.getElementById('test4').classList.remove('show');
}
test4(event){
  document.getElementById('test4').classList.add('show');
}
  render() {
    return (
      <div className="main-header-section">
       
        <header>
		{/*<div className="header-top">
	   <div className="container">
       <div className="row">
         
          <div className="col-md-9">
          <p className='service-owner'>
		<span><img src={Mail2}  /></span>
		<span>Services Hours 10:00 am - 6:00 pm</span>
		
		</p>
          </div>
         
          <div className="col-md-3 right_sco_icon">
  <p>
		<span>
      <a href="https://www.instagram.com/vertlaboratoire/" target="_blank"><img src={Mail4}  /></a>
      <a href="javascript:void(0)" ><img src={Mail5}  /></a>  
    </span>
	</p>
          </div>
       </div>
	
	 </div>
	  </div>*/}  
	  <div className="container">
	  <div className="row">
	   <div className="col-md-12 mobile"> 
	     <div className="logo">
       <a href="/cosmetics-skincare-manufacturer-uk">
           <img src={"https://ik.imagekit.io/deepak/logo-new_RQuBGP2uu.jpg?updatedAt=1692729297770"}  />
      </a>    
        
        </div>
	   </div>
	   <div className="col-md-12">
     <div className="Mobile_menu">
      <MobileMenu />

     </div>
     <div className="row desktop">
       <div className="col-md-3">    
           <div className="left_logo_frame">
              <a href="/cosmetics-skincare-manufacturer-uk">
                  <img src={"https://ik.imagekit.io/deepak/logo-new_RQuBGP2uu.jpg?updatedAt=1692729297770"}  />
              </a>  
           </div>
       </div>
       <div className="col-md-9">
       <ul className="navbar-nav2 ">  
        <li>
           <a href="/cosmetics-skincare-manufacturer-uk">Home</a> |
        </li>
        <li onMouseOut={() => { document.getElementById('test2').classList.remove('show'); } } onMouseOver={ (event) => {this.test2(event);} }>
        <Link to="#">Services</Link> |
        <div id="test2" className="dropdown-content">  
        <a onClick={ (event) => { this.test(); } } href="/Branding">Branding</a> 
        <a onClick={ (event) => { this.test(); } } href="/custom-formulation">Custom Formulation</a>
        <a onClick={ (event) => { this.test(); } } href="/private-label">Private Label</a>
        <a onClick={ (event) => { this.test(); } } href="/packaging">Packaging</a>  
        <a onClick={ (event) => { this.test(); } } href="/Manufacturing">Manufacturing</a>
        <a onClick={ (event) => { this.test(); } } href="/testing">Testing</a>  

   
       
        
        
        
        </div>  
        </li>
        <li onMouseOut={() => { document.getElementById('test4').classList.remove('show'); } } onMouseOver={ (event) => {this.test4(event);} }>
        <Link to="#">Products</Link> |
        <div id="test4" className="dropdown-content">         
        <a onClick={ (event) => { this.test3(); } } href="/facecare">Face Care
          <ul className="drop_menuheader">
            <li><a onClick={ (event) => { this.test3(); } } href="/facecare/facewash">Face Wash</a></li> 
            <li><a onClick={ (event) => { this.test3(); } } href="/facecare/exfoliation">Face Exfoliation</a></li>
            <li><a onClick={ (event) => { this.test3(); } } href="/facecare/serums">Face Serum</a></li>
            <li><a onClick={ (event) => { this.test3(); } } href="/facecare/cream">Face Cream</a></li>       
            <li><a onClick={ (event) => { this.test3(); } } href="/facecare/lotion">Face Lotion</a></li>  
            <li><a onClick={ (event) => { this.test3(); } } href="/facecare/mask">Face Mask</a></li> 
          </ul>
        
        </a>       
        <a onClick={ (event) => { this.test3(); } } href="/makeup">Makeup</a>
        <a onClick={ (event) => { this.test3(); } } href="/Haircare">Hair Care</a>
        <a onClick={ (event) => { this.test3(); } } href="/babycare">Baby Care</a> 
        <a onClick={ (event) => { this.test3(); } } href="/Bodycare">Body Care</a>   
       
        <a onClick={ (event) => { this.test3(); } } href="/Mencare">Men Care</a>
        <a onClick={ (event) => { this.test3(); } } href="/hotel-and-spa-amenities">Hotel Amenities</a> 
        
         
        </div>
        </li>
       
        <li>
        <a href="/about-us">About Us</a> |
        </li>
        <li><a href="/blog"> Blog </a> | </li>
        <li><a href="/contact"> Contact </a>  </li>
        
        </ul>
       </div>
     </div>

	   </div>
        </div>  
		  </div>
		  
        </header>
      </div>
    );
  }
}
export default Navbar;
